<template>
  <div>
    <section class="w-full">
      <header-register
      :showButtonView="false"
      >{{ $t("sectionAdmCartoes.cardAdministrator") }}
    </header-register>
    <main class="mt-3">
      <ViewCard />
    </main>
    </section>
  </div>
</template>

<script>
  import ViewCard from '@/components/administratorCard/components/View/ViewCard.vue';
  import HeaderRegister from "@core/components/register/HeaderRegister.vue";

export default {
  name: "AdmCardView",
    components: {
      ViewCard,
      HeaderRegister
    },
    data() {
      return {
      };
    },
  }
</script>