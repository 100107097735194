<template>
<main>
  <ValidationObserver ref="observer" v-slot="{ validate  }">
  <b-form @submit.stop.prevent="validate().then(submit)">
    <div class="grid grid-cols-1 md:grid-cols-12">
      <div class="col-span-8">
        <div class="grid grid-cols-12 gap-6">
          <div class="col-span-12 lg:col-span-12">
            <input-required
              :rules="{ required:true, max:100}"
              max="100"
              v-model="viewModel.NomeAdministradora"
              :label="$t('sectionAdmCartoes.nome')"
              disabled="true"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-12 mt-3">
      <div class="col-span-8">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 xl:col-span-4">
            <input-default
              :rules="{ positive:true,integer:true}"
              :label="$t('sectionAdmCartoes.recebimento')"
              type="number"
              v-model="viewModel.PrazoRecebimentoAdministradora"
              disabled="true"
            />
          </div>
          <div class="col-span-12 lg:col-span-4">
            <input-default
            :rules="{ positive:true,integer:true}"
              :label="$t('sectionAdmCartoes.desconto')"
              type="number"
              v-model="viewModel.DescontoAdministradora"
              disabled="true"
            />
          </div>
          <div class="col-span-12 lg:col-span-4">
            <CheckboxDefault :label="$t('sectionAdmCartoes.parcelar')" disabled="true" v-model="viewModel.ParcelarTaxaAdm"  />
          </div>
        </div>
      </div>
    </div>
            <div
              class=" flex col-8 padding-0 border__top border__bottom mt-3"
              style="font-size: small;"
            >
              <div class="flex col-5 padding-0">
                <div
                class=" 2xl:w-20 p-2 col-5 padding-0 border__right"
                :class="{
                }"
              >
                <span class="text-red-500 ">{{
                  $t("sectionAdmCartoes.gerenciadorTef")
                }}</span>
              </div>
                <div class="col-7 padding-right-0  border__right">
                    <radioButton :options="options" disabled="true" v-model="viewModel.GerenciadoraAdministradora" :label="$t('sectionAdmCartoes.gerenciadorTef')" stacked />
                </div>
              </div>
             <div class="col-7 grid padding-0">
              <div
                class="flex-1 flex  col-12   border__right border__bottom"
                :class="{
                }"
              >
              <div class="flex p-2  justify-center text-red-500  border__right col-4">
                <span class="text-red-500">{{
                  $t("sectionAdmCartoes.modalidade")
                }}</span>
              </div>
                <div class="flex flex-col p-2 gap-1 col-6">
                  <radioButton disabled="true" :options="optionsModalidade" v-model="viewModel.TipoAdministradora" :label="$t('sectionAdmCartoes.modalidade')" stacked />
                </div>
              </div>
              <div
                class="flex-1 flex col-12  border__right border__bottom"
                :class="{
                }"
              >
              <div class="flex p-2  justify-center text-red-500  border__right col-4">
                <span class="text-red-500" v-show="viewModel.GerenciadoraAdministradora == 0">{{
                  $t("sectionAdmCartoes.Cielo")
                }}</span>
              </div>
                <div class="flex flex-col p-2 gap-1 col-6">
                  <div v-show="viewModel.GerenciadoraAdministradora == 0">
                  <CheckboxDefault disabled="true" :label="$t('sectionAdmCartoes.troco')" v-model="viewModel.CieloPremia.SnTroco"  />
                  <CheckboxDefault disabled="true" :label="$t('sectionAdmCartoes.desconto')" v-model="viewModel.CieloPremia.SnDesconto"  />
                  <CheckboxDefault disabled="true" :label="$t('sectionAdmCartoes.viaDiferente')" v-model="viewModel.CieloPremia.SnViasDiferenciadas"  />
                  <CheckboxDefault disabled="true" :label="$t('sectionAdmCartoes.cupomReduzido')" v-model="viewModel.CieloPremia.SnCupomReduzido"  />
                  </div>
                </div>
              </div>
             </div>
            </div>

    <div class="grid grid-cols-1 md:grid-cols-12 mt-3">
      <div class="col-span-8 border-solid border-b border-gray-300">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 xl:col-span-6">
            <Dropdown 
              :Items="dropdownItemsFornecedor"
              :valueDefault="$t('sectionAdmCartoes.fornecedor')"
              :label="$t('sectionAdmCartoes.fornecedor')"
              disabled="true"
              v-model="viewModel.CodigoFornecedor ">
            </Dropdown>
          </div>
          <div class="col-span-12 lg:col-span-6">
            <Dropdown 
                :Items="dropdownItemsPlanoConta"
                :valueDefault="$t('sectionAdmCartoes.conta')"
                :label="$t('sectionAdmCartoes.conta')"
                disabled="true"
                v-model="viewModel.PlanoContaId ">
              </Dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 flex items-center justify-start">
      <CheckboxDefault label="Ativo" disabled="true" v-model="viewModel.Ativo"  />
    </div>
    <footer class=" flex items-center justify-start gap-5 my-10">
      <Button
      class="bg-gray-900 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-gray-700 transition-all duration-300"
        buttonText="Voltar"
        :onClick="() => $router.push('/admcartao')"
      />
    </footer>
  </b-form>
</ValidationObserver>
</main>
</template>


<script>
import InputRequired from "@core/components/commons/inputs/InputRequired";
import InputDefault from "@core/components/commons/inputs/InputDefault";
import CheckboxDefault from "@core/components/commons/checkbox/CheckboxDefault";
import Dropdown from "@/@core/components/commons/selectDropdown/Dropdown.vue";
import Button from "@core/components/commons/button/Button";
import radioButton from "@core/components/commons/radioButton/radioButton.vue";
import { ValidationObserver } from "vee-validate";

export default {
  name: 'ViewCard',
  components: {
      InputRequired,
      InputDefault,
      CheckboxDefault,
      radioButton,
      ValidationObserver,
      Dropdown,
      Button,
    },
    data() {
      return {
        modalErroSalvar: false,
        modalError:false,
        modalIsOpen: false,
        dropdownItemsFornecedor: [],
        dropdownItemsPlanoConta: [],
        options: [
        { text: this.$t('sectionAdmCartoes.gerenciadorVisa'), value: "0" },
        { text: this.$t('sectionAdmCartoes.gerenciadorBanri'), value: "1" },
        { text: this.$t('sectionAdmCartoes.gerenciadorConv'), value: "2" },
        { text: this.$t('sectionAdmCartoes.gerenciadorEdm'), value: "3" },
        { text: this.$t('sectionAdmCartoes.gerenciadorTrnCard'), value:"4" },
        { text: this.$t('sectionAdmCartoes.gerenciadorHiper'), value: "5" },
          { text: this.$t('sectionAdmCartoes.gerenciadorIntegracao'), value: "6" }
        ],
        optionsModalidade: [
        { text: this.$t('sectionAdmCartoes.Debito'), value: "0" },
        { text: this.$t('sectionAdmCartoes.Credito'), value: "1" },
        ],
        optionsCielo: [
        { text: this.$t('sectionAdmCartoes.troco'), value: "0" },
        { text: this.$t('sectionAdmCartoes.desconto'), value: "1" },
        { text: this.$t('sectionAdmCartoes.viaDiferente'), value: "3" },
        { text: this.$t('sectionAdmCartoes.cupomReduzido'), value: "4" },
        ],
        viewModel: {
          NomeAdministradora: null,
          DescontoAdministradora:null,
          PrazoRecebimentoAdministradora:null,
          PlanoContaId: null,
          Ativo: false,
          ParcelarTaxaAdm:false,
          CodigoFornecedor: null,
          GerenciadoraAdministradora: null,
          CieloPremia: {
            SnTroco: null,
            SnDesconto: null,
            SnViasDiferenciadas: null,
            SnCupomReduzido:null,
          },
          TipoAdministradora:null
        }
      }
  },
  created () {
    this.ObterSeletorPlanoContas();
    this.ObterSeletorFornecedor();
    this.ObterAdmCartao(this.$router.currentRoute.params.id);
    },
    methods: {
      ObterAdmCartao (id) {
        this.$http({
        url: `/admcartao/obter/${id}`,
        method: "GET",
      }).then((res) => {
        this.viewModel = {
          NomeAdministradora: res.data.nomeAdministradora,
          DescontoAdministradora:res.data.descontoAdministradora,
          PrazoRecebimentoAdministradora:res.data.prazoRecebimentoAdministradora,
          PlanoContaId: res.data.planoContaId,
          Ativo: res.data.ativo,
          ParcelarTaxaAdm: res.data.parcelarTaxaAdm,
          CodigoFornecedor: res.data.codigoFornecedor,
          GerenciadoraAdministradora: res.data.gerenciadoraAdministradora,
          CieloPremia: {
            SnTroco: res.data.cieloPremia.snTroco,
            SnDesconto: res.data.cieloPremia.snDesconto,
            SnViasDiferenciadas: res.data.cieloPremia.snViasDiferenciadas,
            SnCupomReduzido:res.data.cieloPremia.snCupomReduzido,
          },
          TipoAdministradora: res.data.tipoAdministradora
        }
      })
      },
      ObterSeletorFornecedor () {
        this.$http({
        url: "/fornecedor/obter-seletor-fornecedor",
        method: "GET"
      }).then((res) => {
          this.dropdownItemsFornecedor = res.data;
        })
        .catch((erro) => {
          this.loading = false;
          this.$notify({
            data: erro.response.data.erros,
            type: "warn",
            duration: 10000
          });
        });
      },
      ObterSeletorPlanoContas() {
      this.$http({
        url: "/planoConta/obter-select-plano-conta",
        method: "GET"
      }).then((res) => {
          this.dropdownItemsPlanoConta = res.data;
        })
        .catch((erro) => {
          this.loading = false;
          this.$notify({
            data: erro.response.data.erros,
            type: "warn",
            duration: 10000
          });
        });
      },
      closeModal (event) {
      if (event.target === event.currentTarget) {
        this.modalIsOpen = false;
        this.modalErroSalvar = false,
        this.modalError = false;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    }
  }
</script>

<style scoped>
.border__top {
  border-top: 1px solid rgba(200, 200, 200, 0.5);
}

.border__right {
  border-right: 1px solid rgba(200, 200, 200, 0.5);
}

.border__bottom {
  border-bottom: 1px solid rgba(200, 200, 200, 0.5);
}
.padding-0{
  padding: 0!important;
}
.padding-right-0{
  padding-right:0!important;
}
</style>